import { Container, Stack } from '@mui/material';
import OptionsColumn from '#/components/pages/SituationCheck/options-column';
import {
  LIFE_SITUATIONS,
  PROVISION_TOPICS,
} from '#/components/pages/PensionProvision/onboarding/data';
import IntroHead from '#/components/shared/IntroHead';

export default function SituationCheck() {
  return (
    <Container maxWidth="lg" sx={{ marginTop: 6 }}>
      <IntroHead
        title="Lebenssituations-Check"
        description="Jede Lebensphase hat ihre eigenen, einzigartigen Themen. Finden Sie heraus, was für die Phase, in der Sie sich befinden, relevant ist."
      />
      <Stack
        direction={{
          xs: 'column',
          md: 'row',
        }}
        spacing={2}
        sx={{ marginTop: 10, gap: 2 }}
      >
        <OptionsColumn
          title="pensionProvision.onboarding.stepThree.lifeSituations"
          options={LIFE_SITUATIONS}
          inverse
        />
        <OptionsColumn
          title="pensionProvision.onboarding.stepThree.provisionTopics"
          options={PROVISION_TOPICS}
        />
      </Stack>
    </Container>
  );
}
