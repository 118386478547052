import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import InformativeCard from '#/components/shared/informative-card';
import useLocales from '#/hooks/useLocales';
import { Topic } from '#/components/pages/PensionProvision/onboarding/data';

interface OptionsColumnProps {
  title: string;
  options: Topic[];
  inverse?: boolean;
}

export default function OptionsColumn({
  title,
  options,
  inverse = false,
}: OptionsColumnProps) {
  const [, setSearchParams] = useSearchParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { translate } = useLocales();

  const handleServiceSelect = (redirectLink: string) => {
    const subscription_package = state?.subscription_package;
    setSearchParams({
      redirectLink,
      ...(subscription_package && { subscription_package }),
    });
  };

  const handleLandingRedirect = (landingURL: string) => {
    navigate(landingURL);
  };

  return (
    <Box
      sx={{
        width: '100%',
        '& > div': {
          marginTop: 1,
        },
      }}
    >
      <Typography variant="subtitle1">{String(translate(title))}</Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            sm: '1fr 1fr',
            md: 'repeat(2, 1fr)',
          },
          gap: 2,
        }}
      >
        {options.map((topic) => (
          <InformativeCard
            cardTitle={String(translate(topic.title))}
            key={topic.value}
            onServiceSelect={
              // topic.panelURL
              //   ? () => handleServiceSelect(topic.panelURL as string)
              () => handleLandingRedirect(topic.landingURL as string)
            }
            inverse={inverse}
          />
        ))}
      </Box>
    </Box>
  );
}
